<template>
  <div class="center" :style="{ 'background-image': `url(${bg})` }">
    <div class="header-box">
      <img class="logo" src="../assets/logo.png" alt="logo" />
      <!-- <img class="text" src="../assets/text.png" alt="" /> -->
      <div class="text">{{ area_info.bigscreen_top_right }}</div>
    </div>
    <div class="content-box">
      <div class="table">
        <!-- <div class="table-item" v-for="item in 3" :key="item">
          <div style="flex: 1">汪小菲小菲</div>
          <div :style="{ flex: 1.4, fontSize: 3.8 + 'vh' }">
            请到前台护士（阿斯顿）
          </div>
        </div> -->
        <div
          class="table-item"
          :class="
            item.animate
              ? 'flow-colorful animate__animated animate__slower animate__flash animate__infinite'
              : ''
          "
          v-for="(item, index) in dataInfo.slice(0, 8)"
          :key="index"
        >
          <div style="flex: 1">{{ item.patient.cert_name.slice(0, 5) }}</div>
          <div :style="{ flex: 1.4, fontSize:  item.his_area.fontsize + 'vh' }">
            请到{{ item.his_area.addr || "护士台咨询" }}
          </div>
        </div>
      </div>
    </div>
    <div class="foot-box">
      <div>{{ area_info.bigscreen_bottom }}</div>
    </div>
  </div>
</template>

<script setup>
import "animate.css";
import { reactive, ref } from "vue";
import { bg } from "@/assets/base64";

const params = new URLSearchParams(window.location.search);
const area_id = params.get("area_id") || 3;
// const base_url = "https://api.ams.test.ningol.com";
// const base_url = "http://127.0.0.1:8000"
const base_url = "https://api.ams.ningol.com";

const dataInfo = reactive([]);
const area_info = ref({});

const fetchRecords = async () => {
  try {
    const url = `${base_url}/api/big-screen/data`;
    const requestData = {
      area_id,
      token: "eyJpdiI6InJYeF",
      pageSize: 20,
      pageNum: 1,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();

    if (response.ok && data.code === 20000) {
      dataInfo.length = 0;
      dataInfo.push(...data.data.data);
      area_info.value = data.data.area_info;
      dataInfo.forEach((item) => {
        if (item.his_area.addr.length < 6) {
          item.his_area.fontsize = 5.5;
        } else if (item.his_area.addr.length == 6) {
          item.his_area.fontsize = 5.0;
        } else if (item.his_area.addr.length == 7) {
          item.his_area.fontsize = 4.6;
        } else if (item.his_area.addr.length == 8) {
          item.his_area.fontsize = 4.2;
        } else if (item.his_area.addr.length > 8) {
          item.his_area.fontsize = 3.8;
        }
      });
    } else {
      console.error("请求失败:", data);
    }
  } catch (error) {
    console.error("请求时出错:", error);
  }
};
fetchRecords();
setInterval(fetchRecords, 3 * 1000);

const time = ref("");
const updateTime = () => {
  const currentTime = new Date();
  time.value = `${currentTime.getHours().toString().padStart(2, "0")}:
  ${currentTime.getMinutes().toString().padStart(2, "0")}:
  ${currentTime.getSeconds().toString().padStart(2, "0")}`;
  dataInfo.forEach((item) => {
    const callTime = new Date(item.call_time);
    const timeDifference = (currentTime - callTime) / 1000;
    item.animate = timeDifference < 10 ? true : false;
  });
};
setInterval(updateTime, 1000);
</script>
<style scoped>
.center {
  width: 100vw;
  height: 100vh;
  font-size: 18px;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
}

.header-box {
  width: 95.5vw;
  height: 14.44vh;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 7.33vh;
}
.text {
  /* height: 4.46vh; */
  font-family: "STKaiti", "华文楷体", "KaiTi", "楷体", cursive;
  font-size: 5.5vh;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.2vw;
}

.content-box {
  width: calc(95.5vw - 2vw * 2);
  height: calc(100vh - 3.33vh * 2 - 14.44vh - 12vh - 4vh * 2);
  margin: auto;
  padding: 3.33vh 2vw;
  background: #98c6fa;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 1vh;
  overflow: hidden;
  /* gap: 1.5vw; */
}

.table {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 100%;
  color: #0153a6;
  font-size: 5.5vh;
  font-weight: 600;
  gap: 2.3vh;
}

.table-item {
  width: calc(44.75vw - 2vw * 2);
  height: 13vh;
  padding: 0 2vw;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1vh;
  text-align: left;
}

.foot-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 85.5vw;
  height: 12vh;
  margin: 4vh auto;
  padding: 0 5vw;
  font-size: 6vh;
  color: white;
  background: #0266ca;
  border-radius: 1vh;
  text-align: center;
  overflow: hidden;
  white-space: pre-wrap;
  letter-spacing: 0.3vw;
}

.flow-colorful.animate__animated.animate__slower.animate__flash.animate__infinite {
  animation-duration: 3s; /* 原来的 1s 改为 2s */
}
</style>
